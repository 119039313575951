// import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IphoneBackground from "../assets/IphoneLayout.png"; // Import the image file

const IphoneLayout = ({ children }) => {
  // const [isSmallScreen, setIsSmallScreen] = useState(false);

  // useEffect(() => {
  //   // Update screen size state based on the window dimensions
  //   const handleResize = () => {
  //     const screenWidth = window.innerWidth;
  //     const screenHeight = window.innerHeight;
  //     setIsSmallScreen(screenWidth < 289.05 || screenHeight < 600.4);
  //   };

  //   // Set initial value and listen for window resize events
  //   handleResize();
  //   window.addEventListener("resize", handleResize);

  //   // Cleanup event listener on component unmount
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <IphoneContainer>
      <ScreenWrapper>
        <Content>{children}</Content>
      </ScreenWrapper>
    </IphoneContainer>
  );
};

export default IphoneLayout;

// Styled-components for styling
const IphoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;
`;

const ScreenWrapper = styled.div`
  position: relative;
  width: ${"289.05px"};
  height: ${"600.5px"};
  background: ${`url(${IphoneBackground}) no-repeat center center / cover`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ isSmallScreen }) => (isSmallScreen ? "0" : "30px")};
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 269.5px;
  max-height: 586px;
  background-color: #013130;
  border-radius: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
`;

export { IphoneContainer, Content, ScreenWrapper };
