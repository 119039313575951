// src/data/greenProjects.js

const greenProjects = [
  {
    title: "Bisedge 23",
    description:
      "With this loan to Bisedge Holding, we are financing the distribution of electric forklifts for the Nigerian Bottling Company (NBC), a leading consumer packaged goods company and strategic bottling partner of The Coca-Cola Company in Lagos, Nigeria, to minimize diesel usage. In addition to the forklifts, we are financing the shipment cost, service, chargers, as well as spare parts.",
    sdg: 11,
    return: "7%",
    paybackPeriod: "5 years",
    offsetCost: "67 AED",
  },
  {
    title: "Talf Solar 2",
    description:
      "With this loan to Talf Solar, a leader in solar infrastructure development and management in India, we will be financing solar installations for several parties. These include the following projects under implementation:\n• All India Society for Health Aid Education and Research – Operates the Shri Venkateshwar Hospital based in Delhi. Solar system size: 1818 kWp\n• Diamond Educational and Welfare society – Runs the premier world-class school Shri Venkateshwar International School. Solar system size: 364.5 kWp\n• Himanshu Education Society – Runs the premier Jindal Public School located in New Delhi. Solar system size: 67.5 kWp",
    sdg: 11,
    return: "8%",
    paybackPeriod: "7 years",
    offsetCost: "221 AED",
  },
  {
    title: "Ecologo 25",
    description:
      "Ecologo has successfully managed to add a new project to its portfolio and is looking to finance a new project. With this loan to Ecologo, we are financing a 326 kWp solar installation for Phuoc Thanh Plastic Company, a manufacturer of various products ranging from industrial packaging products, table trays, to ice coolers with customers across Vietnam and worldwide.",
    sdg: 11,
    return: "6%",
    paybackPeriod: "5 years",
    offsetCost: "169.9 AED",
  },
];

export default greenProjects;
