import React from "react";
import styled from "styled-components";
import BackIcon from "../assets/backbutton.svg";

const BackButton = ({ onClick }) => {
  return (
    <BackButtonWrapper onClick={onClick}>
      <img src={BackIcon} alt="Back" />
    </BackButtonWrapper>
  );
};

export default BackButton;

// Styled-components for BackButton
const BackButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`;
