// src/App.js
import React from "react";
import "./App.css";
import IphoneLayout from "./components/IphoneLayout";
import HomePage from "./components/HomePage";
import BankStatement from "./components/BankStatement";
import CarbonEmissions from "./pages/CarbonEmissions";
import Recommendations from "./pages/Recommendations";
import GreenInvestments from "./pages/GreenInvestments";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <IphoneLayout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/bankStatement" element={<BankStatement />} />
          <Route path="/carbonEmissions" element={<CarbonEmissions />} />
          <Route path="/recommendations" element={<Recommendations />} />
          <Route path="/greenInvestments" element={<GreenInvestments />} />
        </Routes>
      </IphoneLayout>
    </div>
  );
}

export default App;
