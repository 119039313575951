const BankStatementData = [
  {
    date: "2024-05-20",
    category: "Park",
    mcc_name: "Mall of the Emirates",
    amount: 675.49,
    emissions: 3.38,
  },
  {
    date: "2024-05-23",
    category: "Healthcare",
    mcc_name: "Aster Healthcare",
    amount: 171.51,
    emissions: 5.15,
  },
  {
    date: "2024-05-23",
    category: "Grocery Shopping",
    mcc_name: "Carrefour",
    amount: 22.3,
    emissions: 11.71,
  },
  {
    date: "2024-05-24",
    category: "Public Transport",
    mcc_name: "Dubai Metro",
    amount: 72.75,
    emissions: 13.09,
  },
  {
    date: "2024-05-24",
    category: "Park",
    mcc_name: "Mall of the Emirates",
    amount: 681.96,
    emissions: 3.41,
  },
  {
    date: "2024-05-25",
    category: "Small Electronics",
    mcc_name: "Virgin Megastore",
    amount: 735.81,
    emissions: 73.58,
  },
  {
    date: "2024-05-25",
    category: "Healthcare",
    mcc_name: "Aster Healthcare",
    amount: 684.52,
    emissions: 20.54,
  },
  {
    date: "2024-05-25",
    category: "Park",
    mcc_name: "Mall of the Emirates",
    amount: 43.92,
    emissions: 0.22,
  },
  {
    date: "2024-05-26",
    category: "Streaming",
    mcc_name: "Netflix",
    amount: 88.86,
    emissions: 4.44,
  },
  {
    date: "2024-05-26",
    category: "Cinema",
    mcc_name: "Reel Cinemas",
    amount: 339.2,
    emissions: 3.39,
  },
  {
    date: "2024-05-28",
    category: "Restaurant",
    mcc_name: "Careem Food",
    amount: 99.96,
    emissions: 74.97,
  },
  {
    date: "2024-05-29",
    category: "Small Electronics",
    mcc_name: "Virgin Megastore",
    amount: 625.21,
    emissions: 62.52,
  },
  {
    date: "2024-06-01",
    category: "Clothing",
    mcc_name: "H&M",
    amount: 189.34,
    emissions: 18.93,
  },
  {
    date: "2024-06-02",
    category: "Grocery Shopping",
    mcc_name: "Spinneys",
    amount: 245.68,
    emissions: 12.28,
  },
  {
    date: "2024-06-03",
    category: "Healthcare",
    mcc_name: "MediClinic",
    amount: 523.91,
    emissions: 26.2,
  },
  {
    date: "2024-06-04",
    category: "Restaurant",
    mcc_name: "The Cheesecake Factory",
    amount: 412.32,
    emissions: 30.92,
  },
  {
    date: "2024-06-04",
    category: "Public Transport",
    mcc_name: "Dubai Metro",
    amount: 33.5,
    emissions: 6.7,
  },
  {
    date: "2024-06-05",
    category: "Streaming",
    mcc_name: "Spotify",
    amount: 24.99,
    emissions: 1.25,
  },
  {
    date: "2024-06-06",
    category: "Grocery Shopping",
    mcc_name: "Lulu Hypermarket",
    amount: 98.74,
    emissions: 9.87,
  },
  {
    date: "2024-06-07",
    category: "Restaurant",
    mcc_name: "Zuma Dubai",
    amount: 523.45,
    emissions: 39.25,
  },
  {
    date: "2024-06-08",
    category: "Cinema",
    mcc_name: "VOX Cinemas",
    amount: 150.3,
    emissions: 1.5,
  },
  {
    date: "2024-06-08",
    category: "Clothing",
    mcc_name: "Zara",
    amount: 412.5,
    emissions: 41.25,
  },
  {
    date: "2024-06-09",
    category: "Grocery Shopping",
    mcc_name: "Union Coop",
    amount: 77.22,
    emissions: 3.86,
  },
  {
    date: "2024-06-10",
    category: "Healthcare",
    mcc_name: "HealthHub",
    amount: 342.13,
    emissions: 17.1,
  },
  {
    date: "2024-06-11",
    category: "Streaming",
    mcc_name: "Disney+",
    amount: 44.5,
    emissions: 2.22,
  },
  {
    date: "2024-06-12",
    category: "Restaurant",
    mcc_name: "PF Chang's",
    amount: 322.89,
    emissions: 24.22,
  },
  {
    date: "2024-06-13",
    category: "Small Electronics",
    mcc_name: "Sharaf DG",
    amount: 1005.99,
    emissions: 100.6,
  },
  {
    date: "2024-06-13",
    category: "Grocery Shopping",
    mcc_name: "Carrefour",
    amount: 54.33,
    emissions: 5.43,
  },
  {
    date: "2024-06-14",
    category: "Clothing",
    mcc_name: "Nike",
    amount: 275.0,
    emissions: 27.5,
  },
  {
    date: "2024-06-15",
    category: "Public Transport",
    mcc_name: "RTA Bus",
    amount: 17.25,
    emissions: 3.45,
  },
  {
    date: "2024-06-16",
    category: "Restaurant",
    mcc_name: "Shake Shack",
    amount: 95.67,
    emissions: 7.17,
  },
  {
    date: "2024-06-17",
    category: "Healthcare",
    mcc_name: "Medcare",
    amount: 432.21,
    emissions: 21.6,
  },
  {
    date: "2024-06-18",
    category: "Cinema",
    mcc_name: "Novo Cinemas",
    amount: 121.0,
    emissions: 1.2,
  },
  {
    date: "2024-06-19",
    category: "Restaurant",
    mcc_name: "Texas Roadhouse",
    amount: 230.0,
    emissions: 17.25,
  },
  {
    date: "2024-06-20",
    category: "Grocery Shopping",
    mcc_name: "Carrefour",
    amount: 89.43,
    emissions: 8.94,
  },
  {
    date: "2024-06-21",
    category: "Healthcare",
    mcc_name: "Thumbay Hospital",
    amount: 112.53,
    emissions: 5.63,
  },
  {
    date: "2024-06-22",
    category: "Public Transport",
    mcc_name: "Dubai Taxi",
    amount: 55.1,
    emissions: 7.88,
  },
  {
    date: "2024-06-23",
    category: "Clothing",
    mcc_name: "Adidas",
    amount: 287.9,
    emissions: 28.79,
  },
  {
    date: "2024-06-24",
    category: "Small Electronics",
    mcc_name: "Emax",
    amount: 915.45,
    emissions: 91.55,
  },
  {
    date: "2024-06-25",
    category: "Cinema",
    mcc_name: "VOX Cinemas",
    amount: 136.72,
    emissions: 1.37,
  },
  {
    date: "2024-06-26",
    category: "Restaurant",
    mcc_name: "KFC",
    amount: 78.45,
    emissions: 5.88,
  },
  {
    date: "2024-06-27",
    category: "Grocery Shopping",
    mcc_name: "Lulu Hypermarket",
    amount: 145.34,
    emissions: 14.53,
  },

  // Add more dummy data to reach at least 40 entries
];

export default BankStatementData;
