import React from "react";
import styled from "styled-components";
import BackButton from "./BackButton";
import BankStatementData from "../data/BankStatementData";
import { useNavigate } from "react-router-dom";

const BankStatement = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <TableWrapper>
        <Header>
          <BackButton onClick={() => navigate(-1)} />
          <h1>Bank Statement</h1>
        </Header>
        <Table>
          <thead>
            <tr>
              <Th>Date</Th>
              <Th>Category</Th>
              <Th>MCC_Name</Th>
              <Th>Amount (AED)</Th>
              <Th>Emissions CO2/KG</Th>
            </tr>
          </thead>
          <tbody>
            {BankStatementData.map((transaction, index) => (
              <tr key={index}>
                <Td>{transaction.date}</Td>
                <Td>{transaction.category}</Td>
                <Td>{transaction.mcc_name}</Td>
                <Td>{transaction.amount.toFixed(2)}</Td>
                <Td>{transaction.emissions.toFixed(2)}</Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </Container>
  );
};

export default BankStatement;

// Styled-components for styling
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 269.5px;
  max-height: 586px;
  background-color: #013130;
  border-radius: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 2px 20px 2px;
  box-sizing: border-box;
  font-size: 10px;
`;

const Header = styled.h1`
  font-family: var(--sds-typography-heading-font-family);
  font-size: var(--sds-typography-heading-size-base);
  font-weight: 600;
  line-height: 28.8px;
  background-color: #013130;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #0b584a;
  color: #ebebeb;
  position: sticky;
  top: 0;
  text-align: left;
  padding: 4px;
  font-size: 15px
  border: 1px solid #0b584a;
`;

const Td = styled.td`
  background: #34815c;
  color: #ebebeb;
  text-align: left;
  padding: 8px;
  border: 1px solid #0b584a;
`;

export { Header };
